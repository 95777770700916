const FETCH_MAX_RETRIES = 3;

const fetchCsrfToken = async (csrfPath:string) => {
  const apiBaseUrl = `https://api.${window.location.hostname}`;
  let retriesLeft = FETCH_MAX_RETRIES;
  let finalError = null;

  // Automatically retries failed attempts asynchronously
  while (retriesLeft > 0) {
    try {
      const response = await fetch(`${apiBaseUrl}/${csrfPath}`, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'anti-csrftoken-a2z-request': 'true',
        },
      });
      const tokenValue = response.headers.get('anti-csrftoken-a2z');
      if (tokenValue !== null) {
        return tokenValue;
      } else {
        throw new Error('No csrf token in response headers, status code: ' + response.status);
      }
    } catch (error) {
      retriesLeft--;
      finalError = error;
    }
  }

  console.error(finalError);
  return '';
};

export const fetchAuthenticationCsrfToken = async () => {
  return fetchCsrfToken('getAuthenticationCsrf');
};

export const fetchRegistrationCsrfToken = async () => {
  return fetchCsrfToken('getRegistrationCsrf');
};
