import React from 'react';
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';

interface ResponsiveContentLayoutProps {
  header?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
}

const ResponsiveContentLayout = (props: ResponsiveContentLayoutProps) => {
  return (
    <div className="responsive-content-layout">
      <ContentLayout header={props.header}>
        {props.children}
      </ContentLayout>
    </div>
  );
};

export default ResponsiveContentLayout;
