import React, {useState, useEffect} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router';
import arbManifest from '../../localizations/arb/arbManifest';
import {LocalizationContextBuilder} from '@amzn/arb-tools';
import {MbmProvider} from '@amzn/react-arb-tools';
import {AppLayout} from '@amzn/awsui-components-react';
import {ButtonDropdownProps} from '@amzn/awsui-components-react/polaris/button-dropdown/interfaces.js';
import {CancelableEventHandler} from '@amzn/awsui-components-react/polaris/internal/events/index.js';
import {Locale, supportedLocales, languageLocales} from '../../localizations/LocalizationModule';
import Banner from '../Banner/Banner';

interface LocalizedAppProps {
    locale: Locale,
    homepagePath: string,
}

const LocalizedApp = (props: LocalizedAppProps) => {
  const [locale, setLocale] = useState(props.locale);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setLocale(props.locale);
  }, [props.locale]);

  const mbmOption = {
    arbManifest,
    defaultLocalizationContext: new LocalizationContextBuilder()
        .withDefaultLocale(languageLocales[locale])
        .withLocale(languageLocales[locale])
        .build(),
    resolveBundleUrl: (url?:string) => url ? `${process.env.PUBLIC_URL}/translations/${url}` : undefined,
  };

  const replaceCurrentRouteLocale = (locale:Locale):string => {
    const currentPath = location.pathname;
    const firstSeparator = currentPath.indexOf('/');
    const secondSeparator = currentPath.indexOf('/', firstSeparator + 1);
    if (secondSeparator === -1 || secondSeparator - firstSeparator !== 3) {
      const fragment = currentPath.substring(firstSeparator + 1);
      return `/${fragment}`;
    } else {
      const fragment = currentPath.substring(secondSeparator + 1);
      return `/${locale}/${fragment}`;
    }
  };

  const onLanguageSelect:CancelableEventHandler<ButtonDropdownProps.ItemClickDetails> = (event) => {
    const newLocale:Locale | undefined = supportedLocales.find((x) => x === event.detail.id);
    if (newLocale !== undefined) {
      const newRoute = replaceCurrentRouteLocale(newLocale);
      if (newRoute !== location.pathname) {
        navigate(newRoute);
      } else {
        setLocale(newLocale);
      }
    }
  };

  return (
    <MbmProvider {...mbmOption}>
      <div id="h">
        <Banner
          homepagePath={props.homepagePath}
          currentLocale={locale}
          onLanguageSelect={onLanguageSelect}
        />
      </div>
      <AppLayout
        toolsHide={true}
        navigationHide={true}
        headerSelector="#h"
        content={<Outlet />}
      />
    </MbmProvider>
  );
};

export default LocalizedApp;
