import React from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';

interface CenteredContainerProps {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment
      | React.ReactPortal,
  header: React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment
    | React.ReactPortal,
  footer?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment
    | React.ReactPortal
}

const CenteredContainer = (props: CenteredContainerProps) => {
  return (
    <Container header={props.header} footer={props.footer}>
      <div className="centered-container">
        {props.children}
      </div>
    </Container>
  );
};

export default CenteredContainer;
