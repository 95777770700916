import React from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import ResponsiveContentLayout from '../../components/Layout/ResponsiveContentLayout';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

const NotFound = () => {
  const bundle = useAuthgardBundle();

  return (
    <ResponsiveContentLayout>
      <Alert
        statusIconAriaLabel="Error"
        type="error"
        header={bundle.getMessage('app_not-found_title')}
      >
        {bundle.getMessage('app_not-found_description',
            {defaultMessage: 'This page could not be found'},
        )}
      </Alert>
    </ResponsiveContentLayout>
  );
};

export default NotFound;
