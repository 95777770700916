/* eslint-disable max-len */
import React, {useState, useEffect} from 'react';
import Form from '@amzn/awsui-components-react/polaris/form';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Input from '@amzn/awsui-components-react/polaris/input';
import ResponsiveContentLayout from '../Layout/ResponsiveContentLayout';
import PinRegistrationFailure from '../Alerts/PinRegistrationFailure';
import TacAndPinRegistrationInfo from '../Alerts/TacAndPinRegistrationInfo';
import PinRegistrationSuccessAutoLogout from '../Alerts/PinRegistrationSuccessAutoLogout';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

interface PinInputProps {
  onSubmit: (pin: string, reenteredPin: string) => Promise<boolean>
}

interface UserDataProps {
  readonly userName: string;
}

const PinInput = (props: PinInputProps & UserDataProps) => {
  const [pin, setPin] = useState('');
  const [reenteredPin, setReenteredPin] = useState('');
  const [pinError, setPinError] = useState('');
  const [pinRegSuccess, setPinSuccess] = useState<boolean|undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const bundle = useAuthgardBundle();

  const submitPin = async () => {
    setLoading(true);
    const success = await props.onSubmit(pin, reenteredPin);
    setLoading(false);
    if (success) {
      setPinSuccess(true);
    } else {
      setPinSuccess(false);
      handleReset();
    }
  };

  const handleReset = () => {
    setPin('');
    setReenteredPin('');
  };

  useEffect(() => {
    if (pin || reenteredPin) {
      const weakPasswordExp = new RegExp('^(?!(\\d)\\1{5}|012345|123456|234567|345678|456789|567890|098765|987654|876543|765432|654321|543210)\\d{6}$', 'gm');
      const isPasswordWeak = weakPasswordExp.test(pin);

      if (pin.length !== 6) {
        setPinError(bundle.getMessage('pin_length_error'));
      } else if (!isPasswordWeak) {
        setPinError(bundle.getMessage('pin_weak_error'));
      } else if (pin !== reenteredPin) {
        setPinError(bundle.getMessage('pin_match_error'));
      } else {
        setPinError('');
      }
    } else {
      setPinError('');
    }
  }, [pin, reenteredPin]);

  const handlePinChange = (event:any) => {
    const newPin = event.detail.value.slice(0, 6);
    setPin(newPin);
  };

  const handleReenteredPinChange = (event:any) => {
    const newReenteredPin = event.detail.value.slice(0, 6);
    setReenteredPin(newReenteredPin);
  };

  let pinRegistrationAlert;
  if (pinRegSuccess !== undefined) {
    pinRegistrationAlert = pinRegSuccess ? <PinRegistrationSuccessAutoLogout/> : <PinRegistrationFailure/>;
  } else {
    pinRegistrationAlert = <TacAndPinRegistrationInfo/>;
  }

  const header: JSX.Element = <Header
    variant="h1"
    description={bundle.getMessage('pin_page_title_description')}
  >
    {bundle.getMessage('registration_title', {userName: props.userName})}
  </Header>;

  const footer: JSX.Element = <div style={{display: 'flex', justifyContent: 'end'}}>
    <SpaceBetween direction="horizontal" size="s" >
      <Button formAction="none" onClick={handleReset}>
        {bundle.getMessage('pin_clear_button')}
      </Button>
      <Button
        variant="primary"
        disabled={pinError !== ''|| pin == ''}
        onClick={submitPin}
        loading={loading}
      >
        {bundle.getMessage('submit_button')}
      </Button>
    </SpaceBetween>
  </div>;

  return (
    <ResponsiveContentLayout>
      <SpaceBetween size='l'>
        {pinRegistrationAlert}
        {!pinRegSuccess && (
          <Container header={header} footer={footer}>
            <Form errorText={pinError}>
              <SpaceBetween direction="vertical" alignItems="center" size="l">
                <FormField label={bundle.getMessage('pin_label_enter')}>
                  <Input
                    autoFocus
                    value={pin}
                    type="number"
                    onChange={handlePinChange}
                    placeholder="012345"
                    data-testid="pin-input"
                    inputMode="numeric"
                    data-css="password"
                  />
                </FormField>
                <FormField label={bundle.getMessage('pin_label_reenter')}>
                  <Input
                    value={reenteredPin}
                    type="number"
                    onChange={handleReenteredPinChange}
                    placeholder="012345"
                    data-testid="pin-reenter-input"
                    inputMode="numeric"
                    data-css="password"
                  />
                </FormField>
              </SpaceBetween>
            </Form>
          </Container>
        )}
      </SpaceBetween>
    </ResponsiveContentLayout>
  );
};

export default PinInput;
