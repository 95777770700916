import React from 'react';
import {Alert} from '@amzn/awsui-components-react';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

const PinRegistrationFailure = () => {
  const bundle = useAuthgardBundle();
  return (
    <Alert
      statusIconAriaLabel="Error"
      type="error"
      data-testid="pin-registration-failure"
      header={bundle.getMessage('pin_registration_failure_title')}
    >
      {bundle.getMessage('pin_submission_failed')}
    </Alert>
  );
};

export default PinRegistrationFailure;
