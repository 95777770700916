import React from 'react';
import {Alert} from '@amzn/awsui-components-react';
import {handleRegLogout} from '../../handlers/Common/LogoutHandlers';
import {AUTO_LOGOUT_TIME_IN_MS} from '../../utils/constants';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

const PinRegistrationSuccessAutoLogout = () => {
  setTimeout(() => {
    handleRegLogout(false);
  }, AUTO_LOGOUT_TIME_IN_MS);

  const bundle = useAuthgardBundle();

  return (
    <Alert
      statusIconAriaLabel="Success"
      type="success"
      data-testid="pin-registration-success-auto-logout"
    >
      {bundle.getMessage('pin_submission_successful')}
    </Alert>
  );
};

export default PinRegistrationSuccessAutoLogout;
