import React from 'react';
import {Alert} from '@amzn/awsui-components-react';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

const AuthenticationSuccess = () => {
  const bundle = useAuthgardBundle();
  return (
    <Alert
      statusIconAriaLabel="Success"
      type="success"
      data-testid="authentication-success"
    >
      {bundle.getMessage('authentication_success_title')}
    </Alert>
  );
};

export default AuthenticationSuccess;
