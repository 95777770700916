export const fetchUserDetails = async () => {
  const apiBaseUrl = `https://api.${window.location.hostname}`;
  try {
    const response = await fetch(`${apiBaseUrl}/identity/getUser`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    } else {
      console.error('Error retrieving user details due to status code: ' + response.status);
      return Promise.resolve(null);
    }
  } catch (error) {
    // TODO: More elegant error handling to be provided
    console.error('Error retrieving user details: ', error);
    return Promise.resolve(null);
  }
};

export const fetchAuthnRuleId = (): string =>
  new URLSearchParams(window.location.search).get('ar_id') || '';


export const handleUserAuthnSelection = async (authnMethod:string, csrfToken: string) => {
  const apiBaseUrl = `https://api.${window.location.hostname}`;
  if (!csrfToken || csrfToken == '') {
    return false;
  }
  try {
    const response = await fetch(`${apiBaseUrl}/updateUserSelectedAuthenticationMethod`, {
      method: 'PUT',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'anti-csrftoken-a2z': csrfToken,
      },
      body: JSON.stringify({
        userSelectionAuthnMethod: authnMethod,
      }),
    });
    if (!response.ok) {
      console.error('Error updating user authentication method: ' + response.status);
      return false;
    }
  } catch (error) {
    console.log('Error updating user authentication method: ', error);
    return false;
  }
  return true;
};
