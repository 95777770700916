export const getCurrentTime = async () => {
  const apiBaseUrl = `https://api.${window.location.hostname}`;
  try {
    const response = await fetch(`${apiBaseUrl}/getCurrentTime`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    });
    if (response.ok) {
      const data = await response.json();
      return data.epochTime as number;
    } else {
      throw new Error(`Response code while fetching server time: ${response.status}`);
    }
  } catch (error) {
    console.error('Could not fetch server time', error);
    console.warn('Defaulting to client time');
    return Date.now();
  }
};
