import React from 'react';
import {Alert} from '@amzn/awsui-components-react';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

const TacAuthenticationSuccess = () => {
  const bundle = useAuthgardBundle();
  return (
    <Alert
      statusIconAriaLabel="Success"
      type="success"
      data-testid="tac-authentication-success"
    >
      {bundle.getMessage('tac_authentication_success_title')}
    </Alert>
  );
};

export default TacAuthenticationSuccess;
