import {getCurrentTime} from './ServerTimeHandlers';

export interface PinPayload {
  iss: string;
  iat: number;
  pin: string;
}

export const fetchIdentityPublicKey = async () => {
  const apiBaseUrl = `https://api.${window.location.hostname}`;
  try {
    const jwksResponse = await fetch(`${apiBaseUrl}/identity/jwks.json`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const jwksJson = await jwksResponse.json();
    const pinEncryptionKey = jwksJson.keys.find((key: any) => key.kid == 'pin-encrypt');
    const encPublicKey = await window.crypto.subtle.importKey(
        'jwk',
        pinEncryptionKey,
        {
          name: 'RSA-OAEP',
          hash: 'SHA-256',
        },
        true,
        ['encrypt'],
    );
    return encPublicKey;
  } catch (error) {
    console.log('Error fetching and parsing pin encryption key: ', error);
  }
};

/**
 * Helper function to convert array buffer into a string
 * @param {arrayBuffer} arrayBuffer
 * @return {string}
 */
function abToStr(arrayBuffer: ArrayBuffer):string {
  return String.fromCharCode.apply(null, Array.from(new Uint8Array(arrayBuffer)));
}

/**
 *
 * @param {string} pin the plaintext pin
 * @param {CryptoKey} publicKey key used for encryption
 * @return {string} a JWE containg the pin payload
 */
export const encryptPin = async (pin: string, publicKey:CryptoKey) => {
  try {
    const pinPayload:PinPayload = {
      iss: window.location.origin,
      iat: await getCurrentTime(),
      pin: pin,
    };
    const encrypted = await window.crypto.subtle.encrypt(
        {
          name: 'RSA-OAEP',
        },
        publicKey,
        new TextEncoder().encode(JSON.stringify(pinPayload)),
    );
    const pinJwe = window.btoa(abToStr(encrypted));
    return pinJwe;
  } catch (error) {
    console.log('Error encrypting pin: ', error);
  }
};
