import React from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import {handleAuthLogout} from '../../handlers/Common/LogoutHandlers';
import {AUTO_LOGOUT_TIME_IN_MS} from '../../utils/constants';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

const AuthenticationFailureAutoLogout = () => {
  const bundle = useAuthgardBundle();

  setTimeout(() => {
    handleAuthLogout();
  }, AUTO_LOGOUT_TIME_IN_MS);

  return (
    <Alert
      statusIconAriaLabel="Error"
      type="error"
      data-testid="authentication-failure-auto-logout"
      header={bundle.getMessage('authentication_failure_title')}
    >
      {bundle.getMessage('authentication_failure_auto-logout_description')}
    </Alert>
  );
};

export default AuthenticationFailureAutoLogout;
