export interface AuthgardCredentialResponse {
    id: string,
    type: string,
    response: {
        clientDataJSON: string,
        authenticatorData: string,
        signature: string,
        userHandle: string
    }
}

export const fetchAuthenticationChallenge = async () => {
  const apiBaseUrl = `https://api.${window.location.hostname}`;

  try {
    const params = new URLSearchParams({
      rpId: window.location.hostname,
    });

    const response = await fetch(`${apiBaseUrl}/fido2/createAuthenticationChallenge?${params}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const challengeResponse = await response.json();
      return challengeResponse;
    } else {
      console.error('Error retrieving authentication challenge due to status code: ' + response.status);
      return null;
    }
  } catch (error) {
    // TODO: show error page in case of error, to be implemented in this ticket:
    // https://issues.amazon.com/issues/V892367636
    console.error('Error retrieving authentication challenge: ', error);
    return null;
  }
};

export const getCredentials = async (credentialOptions: { challenge: string; }) => {
  const clonedCredentialOptions = JSON.parse(JSON.stringify(credentialOptions));
  clonedCredentialOptions.challenge = str2ab(credentialOptions.challenge);

  let response:AuthgardCredentialResponse | undefined;
  await navigator.credentials.get({publicKey: clonedCredentialOptions}).then((creds: Credential | null) => {
    if (creds == null) {
      const errorMessage = 'Invalid credentials received from the FIDO2 device';
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
    if (creds.response != null) {
      const credsResponse = creds.response;
      response = {
        id: creds.id,
        type: creds.type,
        response: {
          clientDataJSON: ab2str(credsResponse.clientDataJSON),
          authenticatorData: ab2str(credsResponse.authenticatorData),
          signature: ab2str(credsResponse.signature),
          userHandle: ab2str(credsResponse.userHandle),
        },
      };
    } else {
      const errorMessage = 'Invalid credential response received from the FIDO2 device';
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  }).catch((err) => {
    console.error('Error in webauthn get method: ', err);
    throw err;
  });
  return response;
};

export const validateAssertion = async (creds:AuthgardCredentialResponse, csrfToken:string) => {
  if (!csrfToken || csrfToken == '') {
    return false;
  }
  const apiBaseUrl = `https://api.${window.location.hostname}`;

  try {
    const response = await fetch(`${apiBaseUrl}/fido2/validateToken`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'anti-csrftoken-a2z': csrfToken,
      },
      body: JSON.stringify(creds),
    });

    if (response.ok) {
      return true;
    } else {
      console.error('Error validating token due to status code: ' + response.status);
      return false;
    }
  } catch (error) {
    console.error('Error validating credentials: ', error);
    return false;
  }
};

/**
 * Converts array buffer to a string
 * @param {ArrayBuffer} buf
 * @return {String} String that was converted from array buffer
 */
function ab2str(buf: Iterable<number> | ArrayBuffer | null) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return btoa(String.fromCharCode.apply(null, new Uint8Array(buf)))
      .replace(/\//g, '_').replace(/\+/g, '-').replace(/=*$/, '');
}

/**
 * Converts string to array buffer
 * @param {String} enc
 * @return {ArrayBuffer} Array buffer that was converted from string
 */
function str2ab(enc: string) {
  const str = atob(enc.replace(/_/g, '/').replace(/-/g, '+'));
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}
