import React from 'react';
import {Alert} from '@amzn/awsui-components-react';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

const AuthenticationFailure = () => {
  const bundle = useAuthgardBundle();

  return (
    <Alert
      statusIconAriaLabel="Error"
      type="error"
      data-testid="authentication-failure"
      header={bundle.getMessage('authentication_failure_title')}
    >
      {bundle.getMessage('authentication_failure_description')}
    </Alert>
  );
};

export default AuthenticationFailure;
