export const redirectToAuthorizeResume = (requestId:string) => {
  if (requestId == null || requestId == '') {
    throw new Error('Could not find request id query param');
  }
  const params = new URLSearchParams({
    request_id: requestId,
  });
  const redirectUrl = `https://api.${window.location.hostname}/oidc/authorize/resume?${params}`;
  window.location.replace(redirectUrl);
};
