export interface AuthgardCredentialResponse {
    id: string,
    type: string,
    response: {
        attestationObject: string,
        clientDataJSON: string
    }
}

export const createCredentials = async (credentialOptions: { challenge: string; user: { id: string; }; }) => {
  const clonedCredentialOptions = JSON.parse(JSON.stringify(credentialOptions));
  clonedCredentialOptions.challenge = str2ab(credentialOptions.challenge);
  clonedCredentialOptions.user.id = str2ab(credentialOptions.user.id);

  let responseData:AuthgardCredentialResponse | undefined;
  await navigator.credentials.create({publicKey: clonedCredentialOptions}).then((creds: Credential | null) => {
    if (creds != null &&
            creds instanceof PublicKeyCredential &&
            creds.response instanceof AuthenticatorAttestationResponse) {
      responseData = {
        id: creds.id,
        type: creds.type,
        response: {
          clientDataJSON: ab2str(creds.response.clientDataJSON),
          attestationObject: ab2str(creds.response.attestationObject),
        },
      };
    } else {
      const errorMessage = 'Response is not an instance of AuthenticatorAttestationResponse';
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  }).catch((err) => {
    console.error('Error while webauthn create method: ', err);
    throw err;
  });
  return responseData;
};

/**
 * Converts array buffer to a string
 * @param {ArrayBuffer} buf
 * @return {String} String that was converted from array buffer
 */
export function ab2str(buf: Iterable<number> | ArrayBuffer) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return btoa(String.fromCharCode.apply(null, new Uint8Array(buf)))
      .replace(/\//g, '_').replace(/\+/g, '-').replace(/=*$/, '');
}

/**
 * Converts string to array buffer
 * @param {String} enc
 * @return {ArrayBuffer} Array buffer that was converted from string
 */
export function str2ab(enc: string) {
  const str = atob(enc.replace(/_/g, '/').replace(/-/g, '+'));
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

export const fetchRegistrationChallenge = async () => {
  const apiBaseUrl = `https://api.${window.location.hostname}`;

  try {
    const params = new URLSearchParams({
      rpId: window.location.hostname,
    });

    const response = await fetch(`${apiBaseUrl}/fido2/createRegistrationChallenge?${params}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    } else {
      console.error('Error retrieving registration challenge due to status code: ' + response.status);
      return null;
    }
  } catch (error) {
    // TODO: More elegant error handling to be provided
    console.error('Error retrieving registration challenge: ', error);
    return null;
  }
};

export const persistCredentials = async (creds: AuthgardCredentialResponse, csrfToken:string) => {
  if (!csrfToken || csrfToken == '') {
    return false;
  }
  const apiBaseUrl = `https://api.${window.location.hostname}`;

  try {
    const response = await fetch(`${apiBaseUrl}/fido2/registerToken`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'anti-csrftoken-a2z': csrfToken,
      },
      body: JSON.stringify(creds),
    });

    if (response.ok) {
      return true;
    } else {
      console.error('Error registering token due to status code: ' + response.status);
      return false;
    }
  } catch (error) {
    console.error('Error registering token: ', error);
    return false;
  }
};
