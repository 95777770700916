import {useEffect} from 'react';
import bwipjs from 'bwip-js';

export interface GenerateTacProps {
  tac: string,
}

const TacDisplay = (props: GenerateTacProps) => {
  useEffect(() => {
    bwipjs.toCanvas('tac-barcode-canvas', {
      bcid: 'datamatrix',
      text: props.tac,
      scale: 3,
    });
  }, []);
  return <canvas id="tac-barcode-canvas"/>;
};

export default TacDisplay;
