import React from 'react';
import {Alert} from '@amzn/awsui-components-react';
import {handleRegLogout} from '../../handlers/Common/LogoutHandlers';
import {AUTO_LOGOUT_TIME_IN_MS} from '../../utils/constants';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

const RegistrationSuccessAutoLogout = () => {
  setTimeout(() => {
    handleRegLogout(true);
  }, AUTO_LOGOUT_TIME_IN_MS);

  const bundle = useAuthgardBundle();

  return (
    <Alert
      statusIconAriaLabel='Success'
      type='success'
      data-testid='registration-success-auto-logout'
    >
      {bundle.getMessage('registration_success_title')}
    </Alert>
  );
};

export default RegistrationSuccessAutoLogout;
