import React from 'react';
import {useTimer} from 'react-timer-hook';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import './TimerOverlay.css';

export interface TimerOverlayProps {
  timeout: number,
  onExpire?: () => void,
}

const TimerOverlay = (props: TimerOverlayProps) => {
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + props.timeout);

  const {seconds, minutes} = useTimer({
    expiryTimestamp,
    autoStart: true,
    onExpire: props.onExpire,
  });
  return (
    <div
      className="overlay"
      data-testid="timer-overlay"
    >
      <div className="spinner">
        <TextContent>
          <h1>
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </h1>
        </TextContent>
      </div>
    </div>
  );
};
export default TimerOverlay;
