import {UserError, UserErrorsEnum} from '../../utils/userError';

export const handleRegCode = async (regCode: string, csrfToken:string) => {
  if (!regCode || !csrfToken || regCode == '' || csrfToken == '') {
    return false;
  }
  const regCodeResponse = await validateRegCode(regCode, csrfToken);
  return regCodeResponse;
};

export const validateRegCode = async (regCode: string, csrfToken:string) => {
  const apiBaseUrl = `https://api.${window.location.hostname}`;
  try {
    const response = await fetch(`${apiBaseUrl}/identity/validateRegcode`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'anti-csrftoken-a2z': csrfToken,
      },
      body: JSON.stringify({regcode: regCode}),
    });
    if (response.ok) {
      return true;
    } else {
      const responseJson = await response.json();
      const errorStr = responseJson.error + '_error';
      if ((Object.values(UserErrorsEnum) as string[]).includes(errorStr)) {
        throw new UserError(errorStr);
      }
      return false;
    }
  } catch (error) {
    if (error instanceof UserError) {
      throw error;
    }
    console.error('API call error:', error);
    return false;
  }
};
