import React from 'react';
import {useLocation, matchRoutes} from 'react-router-dom';
import TopNavigation, {TopNavigationProps} from '@amzn/awsui-components-react/polaris/top-navigation';
import {ButtonDropdownProps} from '@amzn/awsui-components-react/polaris/button-dropdown/interfaces.js';
import {CancelableEventHandler} from '@amzn/awsui-components-react/polaris/internal/events/index.js';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {useLocalizationContext} from '@amzn/react-arb-tools';
import {languageOptions, supportedLocales, languageLocales, Locale} from '../../localizations/LocalizationModule';
import {handleRegLogout} from '../../handlers/Common/LogoutHandlers';
import {LocalizationContextBuilder} from '@amzn/i18n.localization-context';


/* eslint-disable @typescript-eslint/no-var-requires */
const authgardLogo = require('../../assets/images/authgard_logo.png');

interface BannerProps {
    homepagePath: string
    currentLocale: Locale
    onLanguageSelect: CancelableEventHandler<ButtonDropdownProps.ItemClickDetails>
}

const Banner = (props:BannerProps) => {
  const bundle = useAuthgardBundle();
  const {setLocalizationContext} = useLocalizationContext();

  const i18nStrings = {
    searchIconAriaLabel: bundle.getMessage('banner_search-icon-aria-label'),
    searchDismissIconAriaLabel: bundle.getMessage('banner_search-dismiss-icon-aria-label'),
    overflowMenuTriggerText: bundle.getMessage('banner_overflow-menu-trigger-text'),
    overflowMenuTitleText: bundle.getMessage('banner_overflow-menu-title-text'),
    overflowMenuBackIconAriaLabel: bundle.getMessage('banner_overflow-menu-back-icon-aria-label'),
    overflowMenuDismissIconAriaLabel: bundle.getMessage('banner_overflow-menu-dismiss-icon-aria-label'),
  };

  const languageOptionsItems = supportedLocales.map((locale) => {
    return {id: locale, text: languageOptions[locale]};
  });

  // Enable logout button on specified routes
  const showRegLogoutBtn = matchRoutes([
    {
      path: '/registration',
    },
    {
      path: '/:locale/registration', // Matches locale prefixed routes
    },
    {
      path: '/regcode',
    },
    {
      path: '/:locale/regcode', // Matches locale prefixed routes
    },
  ], useLocation());

  const showSelfServiceLogoutBtn = matchRoutes([
    {
      path: '/subscriber/:subscriber_id/self-service/pin',
    },
    {
      path: '/:locale/subscriber/:subscriber_id/self-service/pin', // Matches locale prefixed routes
    },
    {
      path: '/subscriber/:subscriber_id/self-service/tac',
    },
    {
      path: '/:locale/subscriber/:subscriber_id/self-service/tac', // Matches locale prefixed routes
    },
    {
      path: '/subscriber/:subscriber_id/self-service/tacAndPin',
    },
    {
      path: '/:locale/subscriber/:subscriber_id/self-service/tacAndPin', // Matches locale prefixed routes
    },
  ], useLocation());

  // Build menu utilities
  let utilities: TopNavigationProps.Utility[] = [{
    type: 'menu-dropdown',
    text: languageOptions[props.currentLocale],
    iconName: 'flag',
    onItemClick: (event) => {
      const newLocale:Locale | undefined = supportedLocales.find((x) => x === event.detail.id);
      if (newLocale) {
        const lang = languageLocales[newLocale];
        setLocalizationContext(new LocalizationContextBuilder()
            .withDefaultLocale(lang)
            .withLocale(lang)
            .build());
      }
      props.onLanguageSelect(event);
    },
    items: languageOptionsItems,
  }];

  if (showRegLogoutBtn != null || showSelfServiceLogoutBtn != null) {
    utilities = [...utilities, {
      type: 'button',
      variant: 'primary-button',
      onClick: () => handleRegLogout(showRegLogoutBtn != null),
      text: bundle.getMessage('banner_log-out'),
    }];
  }

  // Make href optional
  interface IdentityProps extends Omit<TopNavigationProps.Identity, 'href'> {
    href?: string;
  }

  interface TopNavProps extends Omit<TopNavigationProps, 'identity'> {
    identity: IdentityProps
  }

  const Navigation = ({...otherProps}: TopNavProps) => (
    // @ts-ignore
    <TopNavigation {...otherProps} />
  );

  return (
    <Navigation
      identity={{
        logo: {src: authgardLogo, alt: 'Authgard Logo'},
      }}
      i18nStrings={i18nStrings}
      utilities={utilities}
    />
  );
};

export default Banner;
