export const supportedLocales = ['en', 'es', 'ja', 'de', 'fr', 'it', 'nl', 'pl'] as const;
export type Locale = typeof supportedLocales[number];

export const languageOptions:Record<Locale, string> = {
  'en': 'English',
  'es': 'Español',
  'ja': '日本語',
  'de': 'Deutsch',
  'fr': 'Français',
  'it': 'Italiano',
  'nl': 'Nederlands',
  'pl': 'Polski',
};

export const languageLocales:Record<Locale, string> = {
  'en': 'en-US',
  'es': 'es-US',
  'ja': 'ja-JP',
  'de': 'de-DE',
  'fr': 'fr-FR',
  'it': 'it-IT',
  'nl': 'nl-NL',
  'pl': 'pl-PL',
};

export const defaultLocale:Locale = 'en';
