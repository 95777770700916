import React, {useState, useEffect} from 'react';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import {useDebounce} from 'usehooks-ts';
import {REQUIRED_TAC_LENGTH} from '../../utils/constants';
import {useHotkeys} from 'react-hotkeys-hook';
import Button from '@amzn/awsui-components-react/polaris/button';
import Form from '@amzn/awsui-components-react/polaris/form';
import {Icon} from '@amzn/awsui-components-react';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

/* eslint-disable @typescript-eslint/no-var-requires */
const qrCodeIcon = require('../../assets/images/qrCodeIcon.svg').default;
const handScannerIcon = require('../../assets/images/handScanner.svg').default;

interface TacInputProps {
  setTac: (tac: string) => void;
  tac: string;
}

const TacInput = ({setTac, tac}: TacInputProps) => {
  const bundle = useAuthgardBundle();

  const [value, setValue] = useState<string>('');
  const [tacError, setTacError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showPasteBtn, setShowPasteBtn] = useState<boolean>(false);

  // Debouncing so that TAC validation only fires once all keys are entered
  const debouncedValue = useDebounce<string>(value, 250);

  useEffect(() => {
    // Firefox: TAC scan triggers a keyboard event for each letter
    document.addEventListener('keydown', handleKeyDown, true);

    // Velocity: Calls onScan method with entire TAC - defined in public/index.html:9
    // https://help.ivanti.com/wl/help/en_us/velocity/1.1/velocity/developingpages/navigate_tags.htm
    (window as any).onScan = (tac: string) => {
      setValue(tac);
    };

    // Remove listener on unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, []);

  const handleKeyDown = (event: any) => {
    setLoading(true);
    const ignoreList = ['Shift', '#', 'Meta', 'Control', 'Alt', 'Enter', 'Tab', 'Unidentified'];
    if (!ignoreList.includes(event.key)) {
      setValue((prevState) => prevState + event.key);
    }
  };

  useEffect(() => {
    if (debouncedValue != '' && debouncedValue.length == REQUIRED_TAC_LENGTH) {
      setTac(value);
      setTacError('');
    } else if (debouncedValue != '') {
      setValue('');
      setTacError(bundle.getMessage('tac_authentication_scan_error'));
    }
    setLoading(false);
  }, [debouncedValue]);

  // Handle reset from the parent
  useEffect(() => {
    if (tac == '') {
      setTacError('');
    }
  }, [tac]);

  // TODO: Remove after UAT and POC
  useHotkeys('shift+3', () => setShowPasteBtn(((prevState) => !prevState)));

  return (
    <Form errorText={tacError}>
      <FormField
        label={
          <SpaceBetween direction="vertical" alignItems="center" size="s">
            <SpaceBetween direction="horizontal" alignItems="center" size="s">
              <img src={qrCodeIcon} width={30} height={30} alt="qr code icon"/>
              <img src={handScannerIcon} width={80} height={80} alt="hand scanner icon"/>
            </SpaceBetween>
            <SpaceBetween direction="horizontal" alignItems="center" size="xxs">
              {loading ? <Spinner /> : <Icon name='status-info' />}
              {bundle.getMessage('tac_authentication_label_scan')}
            </SpaceBetween>
            <div style={{display: showPasteBtn ? 'block' : 'none'}}>
              <Button onClick={async () => {
                const tac = await navigator.clipboard.readText();
                setTac(tac);
              }}
              >
              Paste
              </Button>
            </div>
          </SpaceBetween>
        }>
      </FormField>
    </Form>
  );
};

export default TacInput;
