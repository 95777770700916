import {REQUIRED_PIN_LENGTH, REQUIRED_TAC_LENGTH} from '../../utils/constants';
import {encryptPin} from '../Common/EncryptionPinHandlers';
import {UserErrorsEnum, UserError} from '../../utils/userError';

export const handleTacAndPinAuthentication = async (
    pin: string,
    tac: string,
    csrfToken: string,
    publicKey: CryptoKey | undefined,
): Promise<boolean> => {
  if (!csrfToken || csrfToken == '') {
    return false;
  }
  if (publicKey == undefined) {
    return false;
  }

  if (!pin || !tac || pin.length !== REQUIRED_PIN_LENGTH || tac.length !== REQUIRED_TAC_LENGTH) {
    return false;
  }
  const pinJwe = await encryptPin(pin, publicKey);
  if (pinJwe == undefined) {
    return false;
  }
  return await validateTacAndPin(pinJwe, tac, csrfToken);
};

export const validateTacAndPin = async (
    pinJwe: string,
    tac: string,
    csrfToken: string,
) => {
  const apiBaseUrl = `https://api.${window.location.hostname}`;
  try {
    const response = await fetch(`${apiBaseUrl}/tac/validateAccessCodeAndPin`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'anti-csrftoken-a2z': csrfToken,
      },
      body: JSON.stringify({
        pin: pinJwe,
        accessCode: tac,
      }),
    });
    if (response.ok) {
      return true;
    } else {
      const responseJson = await response.json();
      const errorStr = responseJson.error + '_error';
      if ((Object.values(UserErrorsEnum) as string[]).includes(errorStr)) {
        throw new UserError(errorStr);
      }
      return false;
    }
  } catch (error) {
    if (error instanceof UserError) {
      throw error;
    }
    console.error('API call error:', error);
    return false;
  }
};
