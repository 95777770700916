import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import ResponsiveContentLayout from '../../components/Layout/ResponsiveContentLayout';
import Button from '@amzn/awsui-components-react/polaris/button';
import {isFirefox, isMobile} from 'react-device-detect';
import {
  getCredentials,
  validateAssertion,
  fetchAuthenticationChallenge,
  AuthgardCredentialResponse,
} from '../../handlers/FIDO2/FIDO2AuthenticationHandlers';
import {redirectToAuthorizeResume} from '../../handlers/Common/AuthorizeResumeHandler';
import AuthenticationSuccess from '../../components/Alerts/AuthenticationSuccess';
import AuthenticationFailure from '../../components/Alerts/AuthenticationFailure';
import TimerOverlay from '../../components/TimerOverlay/TimerOverlay';
import AuthenticationFailureAutoLogout from '../../components/Alerts/AuthenticationFailureAutoLogout';
import {handleAuthLogout} from '../../handlers/Common/LogoutHandlers';
import {fetchAuthenticationCsrfToken} from '../../handlers/Common/CsrfHandlers';
import {AUTH_PAGE_TIMEOUT_IN_MS, WEB_AUTHN_TIMEOUT_IN_S} from '../../utils/constants';
import CenteredContainer from '../../components/Layout/CenteredContainer';
import {Icon} from '@amzn/awsui-components-react';
import InternalServerError from '../../components/Alerts/InternalServerError';
import {isLocalEnv} from '../../utils/envHelper';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {fetchAuthnRuleId} from '../../handlers/Common/UserDetailsHandler';
import {additionalAuthnMethodsAllowed} from '../../utils/AuthnRules';

/* eslint-disable @typescript-eslint/no-var-requires */
const securityKey = require('../../assets/images/securityKey.png');

const FIDO2Authentication = () => {
  const bundle = useAuthgardBundle();
  const navigate = useNavigate();
  const location = useLocation();

  const [authenticationSuccess, setAuthenticationSuccess] = useState<boolean>();
  const [showWebauthnTimer, setShowWebauthnTimer] = useState<boolean>(false);
  const [retryLimit, setRetryLimit] = useState<number>(3);
  const [autoLogout, setAutoLogout] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const csrfToken = useRef<string>(location.state?.csrfToken);
  const [authnRuleId, setAuthnRuleId] = useState<string>(location.state?.authnRuleId);
  const [serverError, setServererror] = useState<boolean>(false);
  const [additionalLoginOptionsAllowed, setAdditionalLoginOptionsAllowed] = useState<boolean>(false);
  const requestId = new URLSearchParams(window.location.search).get('request_id') || location.state?.requestId;

  useEffect(() => {
    document.title = bundle.getMessage('authentication_document_title');

    if (!isLocalEnv) {
      handleCsrf();
      handleAuthentication();
    }
    handleAuthnRuleConfigs();

    // Start page timeout
    const pageTimeout = setTimeout(() => {
      handleAuthLogout();
    }, AUTH_PAGE_TIMEOUT_IN_MS);

    // Clear timeout on component unmount
    return () => {
      clearTimeout(pageTimeout);
    };
  }, []);

  useEffect(() => {
    if (retryLimit == 0) setAutoLogout(true);
  }, [retryLimit]);

  const handleCsrf = async () => {
    if (csrfToken.current) return;
    csrfToken.current = await fetchAuthenticationCsrfToken();
    if (!csrfToken.current || csrfToken.current == '') {
      setServererror(true);
    }
  };

  const handleAuthnRuleConfigs = async () => {
    if (!authnRuleId) {
      const fetchedRuleId = fetchAuthnRuleId();
      setAuthnRuleId(fetchedRuleId);
      setAdditionalLoginOptionsAllowed(additionalAuthnMethodsAllowed(fetchedRuleId));
    } else {
      setAdditionalLoginOptionsAllowed(additionalAuthnMethodsAllowed(authnRuleId));
    }
  };

  const handleAuthentication = async () => {
    let creds:AuthgardCredentialResponse | undefined;
    setLoading(true);
    setAuthenticationSuccess(undefined);
    try {
      const challengeResponse = await fetchAuthenticationChallenge();
      if (challengeResponse === null) {
        throw new Error('Could not begin authentication: challenge missing.');
      }

      // Start webauthn prompt
      if (isFirefox) {
        setShowWebauthnTimer(false);
        creds = await getCredentials(challengeResponse);
      } else {
        setShowWebauthnTimer(true);
        creds = await getCredentials(challengeResponse);
        setShowWebauthnTimer(false);
      }

      if (creds === undefined) {
        throw new Error('Could not complete authentication: undefined credentials');
      }

      const success = await validateAssertion(creds, csrfToken.current);
      setAuthenticationSuccess(success);
      setLoading(false);

      if (success) {
        redirectToAuthorizeResume(requestId);
      } else {
        setRetryLimit(retryLimit - 1);
      }
    } catch (error) {
      setRetryLimit(retryLimit - 1);
      setAuthenticationSuccess(false);
      setShowWebauthnTimer(false);
      setLoading(false);
    }
  };

  let authenticationAlert;
  if (autoLogout) {
    authenticationAlert = <AuthenticationFailureAutoLogout/>;
  } else if (authenticationSuccess !== undefined) {
    authenticationAlert = authenticationSuccess ? <AuthenticationSuccess/> : <AuthenticationFailure/>;
  } else if (serverError) {
    authenticationAlert = <InternalServerError/>;
  }

  const showAuthForm = !autoLogout && !authenticationSuccess && !serverError;

  const header: JSX.Element = <Header variant="h1">
    {bundle.getMessage('authentication_title')}
  </Header>;

  const footer: JSX.Element = <div data-css={isMobile ? 'mobile-col' : 'row'}>
    {additionalLoginOptionsAllowed && (
      <Button
        onClick={() => navigate('/authentication/authenticator-selection/',
            {state: {requestId: requestId, csrfToken: csrfToken.current, authnRuleId: authnRuleId}})}
        fullWidth={isMobile}>
        {bundle.getMessage('authentication_back')}
      </Button>
    )}
    <Button
      onClick={handleAuthentication}
      variant="primary"
      loading={loading}
      fullWidth={isMobile}
    >
      {bundle.getMessage('authentication_retry')}
    </Button>
  </div>;

  return (
    <ResponsiveContentLayout>
      <SpaceBetween size="l">
        {authenticationAlert}
        {showAuthForm &&
          <CenteredContainer header={header} footer={footer}>
            <SpaceBetween direction='vertical' alignItems='center' size='s'>
              <img src={securityKey} width={isMobile ? 208.5 : 278} height={isMobile ? 123 : 164} alt="security key"/>
              <div data-css='row'>
                <Icon name="status-info" />
                <p>
                  {bundle.getMessage('authentication_timer_description')}
                </p>
              </div>
            </SpaceBetween>
          </CenteredContainer>
        }
        {showWebauthnTimer &&
          <TimerOverlay
            timeout={WEB_AUTHN_TIMEOUT_IN_S}
            onExpire={() => setShowWebauthnTimer(false)}
          />
        }
      </SpaceBetween>
    </ResponsiveContentLayout>
  );
};

export default FIDO2Authentication;
