import React from 'react';
import {Alert} from '@amzn/awsui-components-react';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

type Props = {
  headerId: string,
  dataId: string,
  messageId: string,
};

// TODO: Fully consolidate remaining alerts
const FailureAlert = (props: Props) => {
  const bundle = useAuthgardBundle();
  return (
    <Alert
      statusIconAriaLabel="Error"
      type="error"
      data-testid={props.dataId ? props.dataId : ''}
      header={props.headerId ? bundle.getMessage(props.headerId) : ''}
    >
      {props.messageId && bundle.getMessage(props.messageId)}
    </Alert>
  );
};

export default FailureAlert;
