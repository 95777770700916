export const handleTacGeneration = async (csrfToken: string) => {
  if (!csrfToken || csrfToken == '') {
    return null;
  }
  const apiBaseUrl = `https://api.${window.location.hostname}`;
  try {
    const response = await fetch(`${apiBaseUrl}/tac/generateAccessCode`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'anti-csrftoken-a2z': csrfToken,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data.accessCode;
    } else {
      console.error('Error generatng TAC due to status code: ' + response.status);
      return null;
    }
  } catch (error) {
    console.error('Error generatng TAC: ', error);
    return null;
  }
};
