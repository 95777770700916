import React from 'react';
import {Alert} from '@amzn/awsui-components-react';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

const TacAndPinRegistrationInfo = () => {
  const bundle = useAuthgardBundle();
  return (
    <Alert
      statusIconAriaLabel="Info"
      type="info"
      data-testid="tac-pin-registration-info"
    >
      {bundle.getMessage('tac_pin_registration_info_description')}
    </Alert>
  );
};

export default TacAndPinRegistrationInfo;
