import React from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

const RegistrationFailure = () => {
  const bundle = useAuthgardBundle();
  return (
    <Alert
      statusIconAriaLabel="Error"
      type="error"
      data-testid="registration-failure"
      header={bundle.getMessage('registration_failure_title')}
    >
      {bundle.getMessage('registration_failure_description')}
    </Alert>
  );
};

export default RegistrationFailure;
