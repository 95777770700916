import React, {useEffect} from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import ResponsiveContentLayout from '../../components/Layout/ResponsiveContentLayout';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

const Logout = (props: { operation: string; }) => {
  const bundle = useAuthgardBundle();

  useEffect(() => {
    document.title = bundle.getMessage(`${props.operation}_log-out_document_title`);
  }, []);

  return (
    <ResponsiveContentLayout>
      <Alert
        statusIconAriaLabel="Success"
        type="success"
        header={bundle.getMessage(`${props.operation}_log-out_title`)}
      >
        {bundle.getMessage(`${props.operation}_log-out_description`,
            {defaultMessage: 'You have been logged out successfully'},
        )}
      </Alert>
    </ResponsiveContentLayout>
  );
};

export default Logout;
