import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@amzn/awsui-global-styles/polaris.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <App />,
);
