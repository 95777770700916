import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import ResponsiveContentLayout from '../../components/Layout/ResponsiveContentLayout';
import Button from '@amzn/awsui-components-react/polaris/button';
import CenteredContainer from '../../components/Layout/CenteredContainer';
import {Box} from '@amzn/awsui-components-react';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {fetchRegistrationUrl} from '../../utils/RegistrationUrlHelper';
/* eslint-disable @typescript-eslint/no-var-requires */
const qrCodeIcon = require('../../assets/images/qrCodeIcon.svg').default;
const usbIcon = require('../../assets/images/usbIcon.svg').default;
const pinIcon = require('../../assets/images/pinIcon.svg').default;

const RegistrationLandingPage = (props: { subscriberId: string }) => {
  const subscriberId = props.subscriberId;
  const bundle = useAuthgardBundle();
  const header: JSX.Element =
      <Header
        variant="h1"
        description={bundle.getMessage('registration_landing_page_description')}
      >
        {bundle.getMessage('registration_landing_page_title')}
      </Header>;

  return (
    <ResponsiveContentLayout>
      <CenteredContainer header={header}>
        <Box padding="xxl">
          <SpaceBetween direction='vertical' size='xxl'>
            <Button
              href = {fetchRegistrationUrl(subscriberId)}
              variant='primary'
              fullWidth
              iconSvg={<img src={usbIcon}/>}
            >
              {bundle.getMessage('registration_register_authenticators')}
            </Button>
            <Button
              href={`https://api.${window.location.hostname}/self-service/pin/${subscriberId}`}
              variant='primary'
              fullWidth
              iconSvg={<img src={pinIcon}/>}
            >
              {bundle.getMessage('registration_reset_pin')}
            </Button>
            <Button
              href={`https://api.${window.location.hostname}/self-service/tac/${subscriberId}`}
              variant='primary'
              fullWidth
              iconSvg={<img src={qrCodeIcon}/>}
            >
              {bundle.getMessage('registration_generate_tac')}
            </Button>
          </SpaceBetween>
        </Box>
      </CenteredContainer>
    </ResponsiveContentLayout>

  );
};

export default RegistrationLandingPage;
