import React from 'react';
import {Alert} from '@amzn/awsui-components-react';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

const InternalServerError = () => {
  const bundle = useAuthgardBundle();
  return (
    <Alert
      statusIconAriaLabel="Error"
      type="error"
      data-testid="internal-server-error"
      header={bundle.getMessage('internal_server_error_title')}
    >
      {bundle.getMessage('internal_server_error_description')}
    </Alert>
  );
};

export default InternalServerError;
