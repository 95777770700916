import React from 'react';
import {Alert} from '@amzn/awsui-components-react';
import {handleRegLogout} from '../../handlers/Common/LogoutHandlers';
import {AUTO_LOGOUT_TIME_IN_MS} from '../../utils/constants';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

const TacPrintSuccessAutoLogout = () => {
  setTimeout(() => {
    handleRegLogout(false);
  }, AUTO_LOGOUT_TIME_IN_MS);

  const bundle = useAuthgardBundle();

  return (
    <Alert
      statusIconAriaLabel="Success"
      type="success"
      data-testid="tac-print-success-auto-logout"
    >
      {bundle.getMessage('tac_generation_success_description')}
    </Alert>
  );
};

export default TacPrintSuccessAutoLogout;
