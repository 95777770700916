import React from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import ResponsiveContentLayout from '../../components/Layout/ResponsiveContentLayout';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {REGISTRATION_ERROR_PAGE_CODE_MAPPING} from '../../utils/constants';

const AuthenticationErrorPage = () => {
  const bundle = useAuthgardBundle();
  const errorCode = new URLSearchParams(window.location.search).get('error_code') ?? '';
  const titleMessage = REGISTRATION_ERROR_PAGE_CODE_MAPPING[errorCode]?.['title'];
  const descriptionMessage = REGISTRATION_ERROR_PAGE_CODE_MAPPING[errorCode]?.['description'];

  return (
    <ResponsiveContentLayout>
      <Alert
        statusIconAriaLabel="Error"
        type="error"
        header={bundle.getMessage(titleMessage ?? 'internal_server_error_title')}
      >
        {bundle.getMessage(descriptionMessage ?? 'internal_server_error_description')}
      </Alert>
    </ResponsiveContentLayout>
  );
};

export default AuthenticationErrorPage;
