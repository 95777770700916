import {useBundle} from '@amzn/react-arb-tools';
import fallbackTranslations from './translation.json';

const useAuthgardBundle = () => {
  const bundleName = 'Authgard';
  const [arbBundle, isArbBundleLoading] = useBundle(bundleName);

  const bundle = {
    getMessage: (message:string, params?:unknown):string => {
      let fallbackMessage = (fallbackTranslations['und'][bundleName] as {[key:string]: string})[message];
      if (!fallbackMessage) fallbackMessage = message;
      try {
        if (isArbBundleLoading) return fallbackMessage;
        if (params) {
          return arbBundle.formatMessage(message, params);
        }
        return arbBundle.getMessage(message);
      } catch (error) {
        console.error(error);
        return fallbackMessage;
      }
    },
  };

  return bundle;
};

export {useAuthgardBundle};
