const DEFAULT_AUTHENTICATION_METHODS = ['fido2', 'tacAndPin', 'badgeAndPinV1'];

const AUTHENTICATION_RULES_BASE:Record<string, Record<string, string[]>> = {
  'oti1x1679qs4mkno491h': {'allowedAuthenticationMethods': DEFAULT_AUTHENTICATION_METHODS},
  'k0ndxg1xuw48juetzplc': {'allowedAuthenticationMethods': ['fido2', 'tacAndPin']},
  'ck1i03zuqile4hhg181x': {'allowedAuthenticationMethods': ['fido2']},
  'encvyp8vda6k8r3zpv5w': {'allowedAuthenticationMethods': DEFAULT_AUTHENTICATION_METHODS},
};

export const fetchAuthnMethodsForRule = async (ruleId: string) => {
  if (ruleId in AUTHENTICATION_RULES_BASE) {
    return AUTHENTICATION_RULES_BASE[ruleId].allowedAuthenticationMethods;
  }
  // If ruleId is not found, fallback to DEFAULT_AUTHENTICATION_METHODS
  return DEFAULT_AUTHENTICATION_METHODS;
};

export const additionalAuthnMethodsAllowed = (ruleId: string) => {
  if (ruleId in AUTHENTICATION_RULES_BASE) {
    return AUTHENTICATION_RULES_BASE[ruleId].allowedAuthenticationMethods.length > 1;
  }
  return true;
};
